<template>
    <side-bar title="BID" :logo="logo" background-color="black">
        <template slot="links" v-if="facilitySet"><!--slot-scope="props" -->
            <user-menu></user-menu>
                <slot></slot>

            <sidebar-item
                :link="{
                        name: facility.id.toUpperCase() + ' Home',
                        icon: 'fas fa-home',
                        path: {
                            name: 'Facility Home',
                            params: facilityRouteParams,
                        },
                        isRoute: true,
                        exact: true
            }"></sidebar-item>

            <template v-if="areaSet">
                <sidebar-item
                    :link="{
                        name: area.slug + ' Area Home',
                        icon: 'fas fa-tachometer-alt',
                        path: {
                            name: 'Area Home',
                            params: areaRouteParams,
                        },
                        exact: true,
                    }"></sidebar-item>
                <sidebar-item
                    v-if="facility.active"
                    :link="{
                        name: 'Lines',
                        icon: 'fas fa-clipboard-list',
                        path: {
                            name: 'Lines',
                            params: areaRouteParams,
                        },
                        exact: true,
                    }"></sidebar-item>
                <sidebar-item
                    v-if="facility.active"
                    :link="{
                        name: 'Leave Slots',
                        icon: 'fas fa-calendar-alt',
                        path: {
                            name: 'Leave',
                            params: areaRouteParams,
                        },
                        exact: true,
                    }"></sidebar-item>
                <sidebar-item
                    v-if="is('arearep', area.id) && facility.active"
                    :link="{
                        name: 'Manage Rosters',
                        icon: 'fas fa-users',
                        path: {
                            name: 'Roster',
                            params: areaRouteParams,
                        },
                        exact: true,
                    }"></sidebar-item>
                <sidebar-item
                    v-if="is('arearep', area.id) && facility.active"
                    :link="{
                        name: 'Manage Rounds',
                        icon: 'fas fa-book',
                        path: {
                            name: 'Round',
                            params: areaRouteParams,
                        },
                        exact: true,
                    }"></sidebar-item>
                <sidebar-item
                    v-if="is('arearep', area.id) && facility.active"
                    :link="{
                        name: 'Bid Summary',
                        icon: 'fas fa-th-list',
                        path: {
                            name: 'Bid Summary',
                            params: areaRouteParams,
                        },
                        exact: true,
                    }"></sidebar-item>
            </template>
        </template>
    </side-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import Sidebar from '../../components/stateless/SidebarPlugin/SideBar'
import UserMenu from './UserMenu'

export default {
  name: 'sidebar',
  components: {
    Sidebar,
    UserMenu
  },
  data () {
    return {
      logo: require('@/assets/img/logos/natca-icon-light-35x35.png')
    }
  },
  methods: {
    role (role) {
      return this.$store.getters['auth/hasRole'](this.facility.id, role)
    }
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      facilitySet: 'facility/isSet',
      areas: 'area/areas',
      area: 'area/area',
      areaSet: 'area/isSet',
      is: 'auth/is',
      user: 'auth/user'
    }),
    facilityRouteParams () {
      return {
        facility: this.facility.id
      }
    },
    areaRouteParams () {
      return {
        facility: this.facility.id,
        area: this.area.slug
      }
    }
  }
}
</script>

<style scoped>

</style>
