<template>
  <div class="card bg-warning p-4">
    <h5 class="card-header text-center text-white mb-4">
      <i class="fas fa-exclamation-triangle"/>
      Facility Not Configured
      <i class="fas fa-exclamation-triangle"/>
    </h5>
    <div class="card-body bg-white">
      <div class="row">
        <div class="col">
          <h3 class="text-center">
            Welcome to BID!
          </h3>

          <p>
            {{ facility.name }} has not been configured to use BID. Clicking the button below will establish some
            default values and settings to get you started. Please watch the webinar tutorial
            <strong><a href="https://natcadcorg.sharepoint.com/:v:/s/ITC/ETR9_n0wi5VDsgMbuwkNk0sBim62tf65mXXAy28bzkT7sw?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZy1MaW5rIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXcifX0%3D&e=ctUtbV"
               target="_blank">here</a></strong> before getting started.
          </p>
          <p>
            <i><strong>** NOTE **</strong> You may need to refresh the page and/or wait a couple minutes for the
              facility to be configured.</i>
          </p>
          <p>
            For support or questions reach out to the ITC at support@natca.org or by clicking the red messenger icon
            at the bottom right of the page. Thanks for using BID!
          </p>
          <div class="w-100 text-center">
            <n-button round
                      type="success"
                      class="bid-button"
                      @click.native="activateFacility()"
                      slot="title">
              Get Started!
            </n-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const FacilityRepository = RepositoryFactory.get('facilities')

export default {
  name: 'facility-setup',
  components: {},
  props: {},
  data () {
    return {}
  },
  methods: {
    /** Activate a new facility */
    activateFacility () {
      FacilityRepository.activate(this.facility.id)
        .then(r => {
          this.$store.commit('facility/setFacility', r.data)
          this.setFacility(this.$store.state.facility.facility)
        })
    }
  },
  computed: {
    ...mapGetters({
      facility: 'facility/facility',
      is: 'auth/is'
    })
  }
}
</script>

<style scoped>

</style>
