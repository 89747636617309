var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-bar',{attrs:{"title":"BID","logo":_vm.logo,"background-color":"black"}},[(_vm.facilitySet)?_c('template',{slot:"links"},[_c('user-menu'),_vm._t("default"),_c('sidebar-item',{attrs:{"link":{
                    name: _vm.facility.id.toUpperCase() + ' Home',
                    icon: 'fas fa-home',
                    path: {
                        name: 'Facility Home',
                        params: _vm.facilityRouteParams,
                    },
                    isRoute: true,
                    exact: true
        }}}),(_vm.areaSet)?[_c('sidebar-item',{attrs:{"link":{
                    name: _vm.area.slug + ' Area Home',
                    icon: 'fas fa-tachometer-alt',
                    path: {
                        name: 'Area Home',
                        params: _vm.areaRouteParams,
                    },
                    exact: true,
                }}}),(_vm.facility.active)?_c('sidebar-item',{attrs:{"link":{
                    name: 'Lines',
                    icon: 'fas fa-clipboard-list',
                    path: {
                        name: 'Lines',
                        params: _vm.areaRouteParams,
                    },
                    exact: true,
                }}}):_vm._e(),(_vm.facility.active)?_c('sidebar-item',{attrs:{"link":{
                    name: 'Leave Slots',
                    icon: 'fas fa-calendar-alt',
                    path: {
                        name: 'Leave',
                        params: _vm.areaRouteParams,
                    },
                    exact: true,
                }}}):_vm._e(),(_vm.is('arearep', _vm.area.id) && _vm.facility.active)?_c('sidebar-item',{attrs:{"link":{
                    name: 'Manage Rosters',
                    icon: 'fas fa-users',
                    path: {
                        name: 'Roster',
                        params: _vm.areaRouteParams,
                    },
                    exact: true,
                }}}):_vm._e(),(_vm.is('arearep', _vm.area.id) && _vm.facility.active)?_c('sidebar-item',{attrs:{"link":{
                    name: 'Manage Rounds',
                    icon: 'fas fa-book',
                    path: {
                        name: 'Round',
                        params: _vm.areaRouteParams,
                    },
                    exact: true,
                }}}):_vm._e(),(_vm.is('arearep', _vm.area.id) && _vm.facility.active)?_c('sidebar-item',{attrs:{"link":{
                    name: 'Bid Summary',
                    icon: 'fas fa-th-list',
                    path: {
                        name: 'Bid Summary',
                        params: _vm.areaRouteParams,
                    },
                    exact: true,
                }}}):_vm._e()]:_vm._e()],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }